import React from 'react';
import {FIND_ALL_CIDADES as query} from '../../graphql/queries';
import {SelectBase} from './mui-base';

const transformOptions = ({content}) => {
  return Array.isArray(content)
    ? content.map((item) => ({
      ...item,
      label: `${item.nome} / ${item.uf}`,
      value: item.id,
    }))
    : [];
};

const getVariables = ({
  pageNumber,
  search = '',
  searchDTO = {},
  pageSize = 20,
}) => {
  return {
    pageable: {pageNumber, pageSize, sortField: 'nome', sortDir: 'ASC'},
    searchDTO: {
      ...searchDTO,
      search,
    },
  };
};

export const SelectCidades = (props) => {
  return (
    <SelectBase
      label="Cidade"
      responseObjectName="cidades"
      transformOptions={transformOptions}
      query={query}
      getVariables={(searchProps) => getVariables({...searchProps, ...props})}
      {...props}
    />
  );
};
