import React from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import moment from 'moment';
import {ReactComponent as KmMidiaLogo} from '../../assets/images/km-midia-logo.svg';
import Number from '../../utils/number';
import 'moment/locale/pt-br';

const Divisor = require('../../assets/images/divisor-relatorio.png');

const ImpressaoOrcamento = ({orcamento, orcamentoItens}) => {
  const classes = useStyles();

  const numeroCidade = orcamentoItens.length;
  let totalValorDescontadoTodosVeiculos = 0;
  let totaisVeiculosMesaisTotais = 0;
  let totaisValorTotalCidade = 0;
  let totaisValorTotalCidadeTodosPeriodos = 0;
  let totalKmEstimado = 0;
  let totalImpactosExternosEstimados = 0;
  let totalImpactosInternosEstimados = 0;
  let somaTotaisImpactos = 0;
  const valorReservaTecnica = (orcamento?.totalVeiculos * 0.1).toFixed();
  const dataProducao = [
    {
      totalVeiculos: orcamento?.totalVeiculos,
      reservaTecnica: `0`,
      carrosProducaoFinal: orcamento?.totalVeiculos,
      valorProducaoLiquido: Number.currencyFormat(orcamento.valorTotalProducao),
    },
  ];
  for (var c = 1; c < orcamento.quantidadeMeses; c++) {
    dataProducao.push({
      totalVeiculos: 0,
      reservaTecnica: `${valorReservaTecnica} (10%)`,
      carrosProducaoFinal: valorReservaTecnica,
      valorProducaoLiquido: Number.currencyFormat(.1 * Number.currencyToFloat(orcamento?.valorTotalProducao)),
    });
  }
  const somaTotaisVeiculosProducao = dataProducao.reduce(
    (accumulator, currentValue) =>
      accumulator + Number.stringToFloat(currentValue.totalVeiculos),
    0,
  );
  const somaReservaTecnica = Number.stringToFloat(
    valorReservaTecnica * (orcamento.quantidadeMeses - 1),
  );
  const somaCarrosProducaoFinal = dataProducao.reduce(
    (accumulator, currentValue) =>
      accumulator + Number.stringToFloat(currentValue.carrosProducaoFinal),
    0,
  );
  const somaProducaoLiquido = dataProducao.reduce(
    (accumulator, currentValue) =>
      accumulator + Number.currencyToFloat(currentValue.valorProducaoLiquido),
    0,
  );
  return (
    <Grid item xs={12} className={classes.reportContainer}>
      <Grid item xs={12} style={{margin: '0 10px'}}>
        <img
          alt={'divisor'}
          style={{width: '100%', margin: '10px 0'}}
          src={Divisor}
        />
      </Grid>
      <Grid item xs={12} className={classes.reportHeader}>
        <Grid item xs={10}>
          <Typography
            className={classes.titleReport}
            style={{fontWeight: '700'}}>
            Proposta:
          </Typography>
          <Typography className={classes.titleReport}>
            {`Cliente: ${orcamento?.anunciante?.nomeFantasia || ''}`}
          </Typography>
          <Typography className={classes.titleReport}>
            {`Título da campanha: ${orcamento?.titulo || ''}`}
          </Typography>
          <Typography className={classes.titleReport}>
            {`Tempo (dias): ${orcamento?.quantidadeMeses * (orcamento?.diasPeriodo?.value || orcamento?.diasPeriodo)} dias`}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <KmMidiaLogo style={{width: '100%'}} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography
          className={classes.titleReport}
          style={{fontWeight: '700'}}>
          Veiculação
        </Typography>
        <Table
          stickyHeader
          aria-label="sticky table"
          className={classes.tableVeiculacao}>
          <TableHead>
            <TableRow style={{fontWeight: '700'}}>
              <TableCell className={classes.tableHead} align={'left'}>
                Cidades
              </TableCell>
              <TableCell className={classes.tableHead} align={'left'}>
                Produto
              </TableCell>
              <TableCell className={classes.tableHead} align={'left'}>
                Valor unit. bruto
              </TableCell>
              <TableCell className={classes.tableHead} align={'left'}>
                Nº de carros
              </TableCell>
              <TableCell className={classes.tableHead} align={'left'}>
                Valor total bruto
              </TableCell>
              <TableCell className={classes.tableHead} align={'left'}>
                Desconto
              </TableCell>
              {orcamento?.quantidadeMeses > 1 && (
                <TableCell className={classes.tableHead} align={'left'}>
                  V. total bruto negociado por período
                </TableCell>
              )}
              <TableCell className={classes.tableHead} align={'left'}>
                V. total bruto negociado {orcamento?.quantidadeMeses > 1 ? 'todos os períodos' : ''}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orcamentoItens.map((item, index) => {
              const valorTotalMensalVeiculo =
                Number.stringToFloat(item?.quantidadeVeiculos) *
                Number.stringToFloat(item?.valorMensalVeiculo);
              const totalNegociadoTodosPeriodos =
                orcamento?.quantidadeMeses * Number.stringToFloat(item?.valorTotalCidade);
              const valorDescontadoTodosVeiculos =
                Number.stringToFloat(item?.quantidadeVeiculos) *
                Number.stringToFloat(item?.valorDescontoPorVeiculo);
              totalValorDescontadoTodosVeiculos =
                totalValorDescontadoTodosVeiculos +
                valorDescontadoTodosVeiculos;
              totaisVeiculosMesaisTotais =
                totaisVeiculosMesaisTotais + valorTotalMensalVeiculo;
              totaisValorTotalCidade =
                totaisValorTotalCidade +
                (Number.stringToFloat(item?.valorTotalCidade) || 0);
              totaisValorTotalCidadeTodosPeriodos =
                totaisValorTotalCidadeTodosPeriodos +
                totalNegociadoTodosPeriodos;
              return (
                <React.Fragment key={index}>
                  <TableRow
                    tabIndex={-1}
                    key={`itemA${index}`}
                    className={classes.tableRowPrincipal}>
                    <TableCell>
                      {`${item?.cidade?.nome}/${item?.cidade?.uf}`}
                    </TableCell>
                    <TableCell>{item?.modeloAnuncio?.nome}</TableCell>
                    <TableCell>{item?.valorMensalVeiculo}</TableCell>
                    <TableCell>{item?.quantidadeVeiculos}</TableCell>
                    <TableCell>
                      {Number.currencyFormat(valorTotalMensalVeiculo)}
                    </TableCell>
                    <TableCell style={{whiteSpace: 'nowrap'}}>
                      {`${Number.currencyFormat(valorDescontadoTodosVeiculos)} (${Number.stringToFloat(item?.desconto)}%)`}
                    </TableCell>
                    {orcamento?.quantidadeMeses > 1 && (
                      <TableCell align={'left'}>
                        {item?.valorTotalCidade}
                      </TableCell>
                    )}
                    <TableCell align={'left'}>
                      {Number.currencyFormat(totalNegociadoTodosPeriodos)}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
            <TableRow tabIndex={-1} key={`itemAfinal`}>
              <TableCell className={classes.tableHead} align="left" style={{whiteSpace: 'nowrap'}}>
                Total de cidades: {numeroCidade}
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell className={classes.tableHead}>
                {orcamento?.totalVeiculos}
              </TableCell>
              <TableCell className={classes.tableHead}>
                {Number.currencyFormat(totaisVeiculosMesaisTotais)}
              </TableCell>
              <TableCell className={classes.tableHead}>
                {Number.currencyFormat(totalValorDescontadoTodosVeiculos)}
              </TableCell>
              {orcamento?.quantidadeMeses > 1 && (
                <TableCell className={classes.tableHead} align="left">
                  {Number.currencyFormat(totaisValorTotalCidade)}
                </TableCell>
              )}
              <TableCell className={classes.tableHead} align="left">
                {Number.currencyFormat(totaisValorTotalCidadeTodosPeriodos)}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell
                rowSpan={1}
                colSpan={8}
                className={classes.footerSpace}>
                <Typography
                  className={classes.footerText}
                  style={{height: 50}}>
                  <br />
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Grid>
      <Grid container className={classes.tableResumoVeiculação}>
        <Grid
          item
          xs={4}
          className={classes.tableHead}
        >
          Valor total da veiculação (Bruto)
        </Grid>
        <Grid item xs={8} className={classes.tablecellResumoVeiculacao}>
          {Number.currencyFormat(totaisValorTotalCidadeTodosPeriodos)}
        </Grid>
        <Grid
          item
          xs={4}
          className={classes.tableHead}
          style={{borderBottom: '1px solid #E0E0E0'}}
        >
          Valor total da veiculação (Líquido)
        </Grid>
        <Grid
          item
          xs={8}
          className={classes.tablecellResumoVeiculacao}
          style={{borderBottom: '1px solid #E0E0E0'}}
        >
          {Number.currencyFormat(
            Number.currencyToFloat(totaisValorTotalCidadeTodosPeriodos) -
            (Number.currencyToFloat(orcamento?.comissaoAgencia) * orcamento.quantidadeMeses)
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{breakInside: 'avoid'}}
        className={classes.table}>
        <Typography
          className={classes.titleReport}
          style={{fontWeight: '700'}}>
          Produção
        </Typography>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead}>Periodos</TableCell>
              <TableCell className={classes.tableHead}>
                Numero de carros
              </TableCell>
              <TableCell className={classes.tableHead}>
                Reserva Tecnica
              </TableCell>
              <TableCell className={classes.tableHead}>
                Total de Carro
              </TableCell>
              <TableCell className={classes.tableHead}>
                Valor total líquido
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataProducao.map((item, index) => {
              return (
                <TableRow
                  tabIndex={-1}
                  key={index}
                  className={classes.tableRowPrincipal}>
                  <TableCell>Período - {index + 1} </TableCell>
                  <TableCell>{item?.totalVeiculos}</TableCell>
                  <TableCell>{item?.reservaTecnica}</TableCell>
                  <TableCell>{item?.carrosProducaoFinal}</TableCell>
                  <TableCell>{item?.valorProducaoLiquido}</TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell className={classes.tableHead} align="left">
                Total
              </TableCell>
              <TableCell className={classes.tableHead} align="left">
                {Number.format(somaTotaisVeiculosProducao)}
              </TableCell>
              <TableCell className={classes.tableHead} align="left">
                {Number.format(somaReservaTecnica)}
              </TableCell>
              <TableCell
                className={classes.tableHead}
                align="left">
                {Number.format(somaCarrosProducaoFinal)}
              </TableCell>
              <TableCell className={classes.tableHead} align="left">
                {Number.currencyFormat(somaProducaoLiquido)}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell
                rowSpan={1}
                colSpan={7}
                className={classes.footerSpace}>
                <Typography
                  className={classes.footerText}
                  style={{height: 50}}>
                  <br />
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Grid>
      {orcamento?.observacoes && (
        <Grid item xs={12} className={classes.observacoes}>
          <Typography className={classes.tableHead}>Observações:</Typography>
          <pre className={classes.observacoesText}>
            {orcamento?.observacoes}
          </pre>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        style={{breakInside: 'avoid'}}
        className={classes.table}>
        <Typography
          className={classes.titleReport}
          style={{fontWeight: '700'}}>
          Métricas estimadas da campanha:
        </Typography>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead}>
                Cidades
              </TableCell>
              <TableCell className={classes.tableHead}>
                Numero de carros contratados
              </TableCell>
              <TableCell className={classes.tableHead}>
                Quilometragem estimada
              </TableCell>
              <TableCell className={classes.tableHead}>
                Impactos externos estimados
              </TableCell>
              <TableCell className={classes.tableHead}>
                Impactos internos estimados
              </TableCell>
              <TableCell className={classes.tableHead}>
                Total de impactos
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orcamentoItens.map((item, index) => {
              const diasPeriodo = item.modeloAnuncio?.diasPeriodo || 30;
              const kmEstimado =
                ((item?.cidade?.kmMedio || item?.cidade?.kmPadrao || 0) /
                  30) *
                diasPeriodo *
                (item?.quantidadeVeiculos || 0);
              const percentualImpactos =
                item.modeloAnuncio?.percentualImpactos || 100;
              const impactosExternosEstimados =
                kmEstimado *
                (item.cidade?.impactadosKm || 30) *
                (percentualImpactos / 100);

              const mediaKmPorCorrida = (
                kmEstimado /
                (item?.quantidadeVeiculos || 1) /
                (diasPeriodo * (orcamento?.quantidadeMeses || 1)) /
                (item?.cidade?.mediaCorridas || 25)
              ).toFixed(2);
              const impactosInternosEstimados =
                (kmEstimado / mediaKmPorCorrida) * 1.4;
              totalKmEstimado = totalKmEstimado + kmEstimado;
              totalImpactosExternosEstimados =
                totalImpactosExternosEstimados + impactosExternosEstimados;
              totalImpactosInternosEstimados =
                totalImpactosInternosEstimados + impactosInternosEstimados;
              const totalImpactos =
                impactosExternosEstimados + impactosInternosEstimados;
              somaTotaisImpactos = somaTotaisImpactos + totalImpactos;
              return (
                <TableRow
                  tabIndex={-1}
                  key={`estimado${index}`}
                  className={classes.tableRowPrincipal}>
                  <TableCell>{item?.cidade?.nome}</TableCell>
                  <TableCell>{item?.quantidadeVeiculos}</TableCell>
                  <TableCell>
                    {Number.format(Number.safeParseInt(kmEstimado))}
                  </TableCell>
                  <TableCell>
                    {Number.format(
                      Number.safeParseInt(impactosExternosEstimados),
                    )}
                  </TableCell>
                  <TableCell>
                    {Number.format(
                      Number.safeParseInt(impactosInternosEstimados),
                    )}
                  </TableCell>
                  <TableCell>
                    {Number.format(Number.safeParseInt(totalImpactos))}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell className={classes.tableHead} align="left">
                Total de cidades : {numeroCidade}
              </TableCell>
              <TableCell className={classes.tableHead} align="left">
                {orcamento?.totalVeiculos}
              </TableCell>
              <TableCell className={classes.tableHead} align="left">
                {Number.format(Number.safeParseInt(totalKmEstimado))}
              </TableCell>
              <TableCell className={classes.tableHead} align="left">
                {Number.format(
                  Number.safeParseInt(totalImpactosExternosEstimados),
                )}
              </TableCell>
              <TableCell className={classes.tableHead} align="left">
                {Number.format(
                  Number.safeParseInt(totalImpactosInternosEstimados),
                )}
              </TableCell>
              <TableCell className={classes.tableHead} align="left">
                {Number.format(Number.safeParseInt(somaTotaisImpactos))}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell
                rowSpan={1}
                colSpan={7}
                className={classes.footerSpace}>
                <Typography
                  className={classes.footerText}
                  style={{height: 50}}>
                  <br />
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Grid>
      <Grid
        item
        xs={12}
        className={`${classes.footer} page-footer`}
        style={{display: 'none'}}>
        <Grid item xs={12} className={classes.footerLogoContainer}>
          <KmMidiaLogo className={classes.footerLogo} />
          <Typography className={classes.footerText}>
            Valorizando sua marca a cada KM rodado!
          </Typography>
        </Grid>
        <img
          alt={'divisor'}
          style={{width: '100%', margin: '10px 0'}}
          src={Divisor}
        />
        <Grid container direction="row" style={{width: '100%', justifyContent: "space-between"}}>
          <Typography className={classes.footerSubText}>
            Proposta gerado no dia{' '}
            {moment().format('DD [de] MMMM [de] YYYY [às] HH[h]mm')}, válido
            por 30 dias
          </Typography>
          <Typography
            className={classes.footerSubText}
            style={{textAlign: 'right'}}>
            {`© Copyright ${moment().format(
              'YYYY',
            )}, KM Mídia CNPJ: 33.793.168/0001-50. Todos os direitos reservados.`}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  tableVeiculacao: {
    '& .MuiTableCell-root': {
      fontSize: '0.7rem',
      backgroundColor: '#fff',
      borderBottom: 'none',
    },
    '& .MuiTableHead-root': {
      '& .MuiTableCell-root': {
        borderBottom: '1px solid #E0E0E0',
      },
    },
  },
  reportContainer: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '0 10px',
  },
  titleReport: {
    paddingTop: 10,
    marginLeft: '15px',
    fontSize: 18,
    color: '#031663',
  },
  reportHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  tableHead: {
    fontWeight: '700',
    fontSize: '0.7rem',
  },
  table: {
    paddingBottom: 100,
    pageBreakBefore: 'always',
    breakInside: 'avoid',
    '& .MuiTableCell-root': {
      fontSize: '0.7rem',
      backgroundColor: '#fff',
      borderBottom: 'none',
    },
    '& .MuiTableHead-root': {
      '& .MuiTableCell-root': {
        borderBottom: '1px solid #E0E0E0',
      },
    },
  },
  tableResumoVeiculação: {
    breakInside: 'avoid',
    width: '100%',
    paddingBottom: 50
  },
  tablecellResumoVeiculacao: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '8px 15px',
    fontSize: '0.7rem',
    lineHeight: '0.8rem',
    backgroundColor: '#fff',
    borderBottom: 'none',
  },

  footer: {
    margin: '0px 10px',
    position: 'fixed',
    width: '100%',
    top: '730px',
  },
  footerSpace: {
    whiteSpace: 'nowrap',
    borderTop: '1px solid #E0E0E0',
  },
  footerLogo: {
    height: '25px',
    width: '110px',
    margin: '6px 20px 0px -5px',
  },
  footerText: {
    margin: '20px 0 0 0',
    fontSize: 14,
    color: '#000',
    textAlign: 'center',
    fontWeight: '700',
  },
  footerSubText: {
    fontSize: 11,
    paddingRight: 10,
  },
  footerLogoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  observacoes: {
    backgroundColor: '#cecece',
    breakInside: 'avoid',
    width: '100%',
    marginTop: 10,
    paddingBottom: 100,
    zIndex: 999,
  },
  observacoesText: {
    whiteSpace: 'normal',
  },
  tableRowPrincipal: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      paddingBottom: 5,
    },
  },
}));

export default ImpressaoOrcamento;

